.rentalagreement{

.summary {
    padding: 16px;
    border: solid 1px #DADADA;
    border-radius: 3px;
    margin-top: 20px;

    text-align: justify;

    font: normal 14px/18px Klavika;

    color: var(--colorTextDarkGray);
}


.tandcs {
    //padding: 30px;
    //border: solid 1px #DADADA;
    border-radius: 3px;
    margin: 20px;

    text-align: left;
    font: normal 14px/18px Klavika;

    color: var(--colorTextDarkGray);

    position: absolute;
    bottom: 87px;
}



.progress {
    position: absolute !important;
    bottom: 20px;
    width: 100%;

    .stepone,
    .steptwo,
    .stepthree {
        width: 100px;
        display: inline-table;
        height: 150px;
        position: relative;
        z-index: 2;
        top: 0;


    }

    .stepone span,
    .steptwo span,
    .stepthree span {
        display: inline-block;
        border: 1px rgba(255, 255, 255, .6) dashed;
        border-radius: 100px;
        color: rgba(255, 255, 255, .6);
        font-size: 23px;
        line-height: 23px;
        text-align: center;

        width: 50px;
        height: 50px;

        padding-top: 13px;
        margin-bottom: 20px;

        background: var(--colorBackgroundBlue);
    }

    .stepone div,
    .steptwo div,
    .stepthree div {
        font: normal 14px/14px klavika;
        color: rgba(255, 255, 255, .6);
    }

    .steptwo {
        //margin: 0 60px;
    }

    .connector {
        border-bottom: 1px #fff dashed;

        width: 180px;
        margin-left: 99px;
        z-index: 1;
        position: relative;
        top: -125px;
    }

    .completed {
        //border-style: solid;
        //position: relative;

        opacity: 1;

        span {
            border-style: solid;
            color: rgba(255, 255, 255, 1);
        }

        div {
            color: rgba(255, 255, 255, 1);
        }
    }
}

.receipt {
    h2 {
        color: #fff !important;
        padding: 0 60px;
        line-height: 20px;

        font: normal 23px/23px Klavika;
    }

    img {
        padding-left: 30px;
    }

    button {
        position: absolute;
        bottom: 20px;
        left: calc(50% - 80px);
    }

}


.section {
    padding: 16px;
}

.slide-zoom{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--colorBackgroundBlue);
}

.slide-zoom h2 {
    font: 600 16px/16px klavika;
    color: #646464;
}

.labelHeader {
    font: 600 16px/20px klavika;
    color: var(--colorTextDarkGray);


    &.centered {
        display: inline-block;
        max-width: 90%;
        text-align: center;
    }
}


.tenantownerWrapper {
    padding: 16px;
}

.factoryWrapper {
    padding: 16px;
}

.campaingWrapper {
    padding: 16px;
}

.farmerWrapper {
    padding: 16px;

    .searchWrapper {
        margin-top: 20px;
    }
}

.summaryWrapper {
    padding: 16px;
}


.btnWrapper {
    position: absolute;
    width: 100%;
    bottom: 0;

    text-align-last: center;
    left: 0;
}
ion-slides, form{
button {
    padding: 15px 30px;
    font: normal 15px/15px klavika;
    border-radius: 50px;
    border: solid 1px #C8C8C8;
    color: var(--colorTextDarkGray);
    background-color: #F6F6F6;
    margin-top: 10px;
    margin-bottom: 30px;
}

button.negative {
    border: solid 1px #fff;
    color: var(--colorBackgroundBlue);
    background-color: #fff;
    margin-top: 30px;
}
button.btnAbsolute {
    position: absolute;
    bottom: 0;
    display: block;
    width: calc(100% - 32px);
    left: 16px;
}
.tenantownerWrapper {
    padding: 16px;
}
}



@media only screen and (min-width: 375px) {
    .summary {
        padding: 30px;
    }
}



@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {


        input[type="text"],
        input[type="number"] {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            -webkit-appearance: none;
        }

        textarea {
            -webkit-appearance: none;
        }

        label.hkg:before {
            padding: 8px 10px 11px 10px;
        }

    }
}

}