/*@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/KlavikaWebBasicLight.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/KlavikaWebBasicLight.woff?-afn3my') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/KlavikaWebBasicLightItalic.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/KlavikaWebBasicLightItalic.woff?-afn3my') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/KlavikaWebBasicRegular.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/KlavikaWebBasicRegular.woff?-afn3my') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/KlavikaWebBasicRegularItalic.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/KlavikaWebBasicRegularItalic.woff?-afn3my') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/KlavikaWebBasicMedium.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/KlavikaWebBasicMedium.woff?-afn3my') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/KlavikaWebBasicMediumItalic.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/KlavikaWebBasicMediumItalic.woff?-afn3my') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/KlavikaWebBasicBold.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/KlavikaWebBasicBold.woff?-afn3my') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/KlavikaWebBasicBoldItalic.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/KlavikaWebBasicBoldItalic.woff?-afn3my') format('woff');
    font-weight: bold;
    font-style: italic;
}*/


@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/Arimo-Regular.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/Arimo-Regular.woff?-afn3my') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/Arimo-Italic.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/Arimo-Italic.woff?-afn3my') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/Arimo-Regular.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/Arimo-Regular.woff?-afn3my') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/Arimo-Italic.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/Arimo-Italic.woff?-afn3my') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/Arimo-Medium.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/Arimo-Medium.woff?-afn3my') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/Arimo-SemiBoldItalic.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/Arimo-SemiBoldItalic.woff?-afn3my') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/Arimo-Bold.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/Arimo-Bold.woff?-afn3my') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'klavika';
    src: url('../assets/fonts/Arimo-BoldItalic.eot?#iefix-afn3my') format('embedded-opentype'),
        url('../assets/fonts/Arimo-BoldItalic.woff?-afn3my') format('woff');
    font-weight: bold;
    font-style: italic;
}

* {
    font-family: 'klavika', 'Roboto', sans-serif;
}