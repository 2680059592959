:root {
    --colorBackgroundLightGray: #EBEBEB;
    --colorBackgroundBlue: #59A8C7;
    --colorBackgroundGreen: #00A25E;
    --colorTextDarkGray: #646464;

    --colorBackgroundLightGrayActive: #D8D8D8;


    --colorGreen: #00A25E;
    --colorBlue: #4b86b4;

}