.mypages{
    font-size: 14px;
}


table.list td:first-child {
    padding-right: 10px;
}


.list {
    
    color: var(--colorTextDarkGray);
    margin: 20px 0;

    td{
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.cardDevider{
    height: 1px;
    border-top: var(--lightBorder);
    margin: 20px -10px 20px -10px;
    &.nomargin{
        margin-top: 0;
    }
}