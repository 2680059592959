
    form:not(.loginForm){
        left: 0;
        top: 0;
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
      }

      

.highlight{
    font-weight: 600;
}

input[type="text"], input[type="number"] {
    width: calc(100% - 50px);
    height: 39px;
    background: #FAFAFA;
    border: none;
    border: solid 1px #DADADA;
    border-left-style: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;

    -webkit-tap-highlight-color: transparent;
    /*-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;*/
}

input:focus {
    outline: none !important;
}

input[type="text"] {
   // -webkit-appearance: none;
}

select {
   // -webkit-appearance: none;
}



label {
    //height: 39px;
    display: block;
}

label.tdr:before,
label.hkg:before,
label.dkk:before
{
    background: #EBEBEB;
    color: var(--colorTextDarkGray);
    border: solid 1px #DADADA;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    float: left;

    padding-top: 11px;
    padding-left: 11px;
    width: 35px;
    height: 26px;
    display: block;
}

label.andele:before {
    background: #EBEBEB;
    color: var(--colorTextDarkGray);
    border: solid 1px #DADADA;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    float: left;
    padding-top: 11px;
    padding-left: 8px;
    width: 46px;
    height: 26px;
    display: block;
}

label.andele:before {
    content: 'Andele';
}

label.tdr:before {
    content: 'tdr.';
}

label.hkg:before {
    content: 'hkg';
}

label.dkk:before {
    content: 'Kr.';
}

form{

button {
    padding: 15px 30px;
    font: normal 15px/15px klavika;
    border-radius: 50px;
    border: solid 1px #C8C8C8;
    color: var(--colorTextDarkGray);
    background-color: #F6F6F6;
    margin-top: 10px;
    margin-bottom: 30px;
}

button.negative {
    border: solid 1px #fff;
    color: var(--colorBackgroundBlue);
    background-color: #fff;
    margin-top: 30px;
}
}