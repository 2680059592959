.filelist{
    border: solid 1px #DADADA;
    border-radius: 4px;
    clear: both;

    list-style: none;

    padding: 0;

    margin:0 10px 20px 10px;
  }

  .filelist button{

    background-color: inherit;

    text-align: left;

    width: 100%;
    height: 100%;

    border-bottom: solid 1px #DADADA;

    padding: 22px 0 18px 50px;

    font: normal 13px/13px klavika;
    color: var(--colorTextDarkGray);
  }
  .filelist li:last-child button{
    border-bottom: none;
  }

  .filelist button:active{
    //background-color: $colorBackgroundLightGrayActive;

  }


  .count{
    background-color: #DADADA;
    width: 23px;
    height: 15px;
    display: block;
    float: right;
    margin-right: 20px;
    text-align: center;

    border-radius: 8px;

    font-size: 12px;
    padding-top: 1px;
  }

  .filelist li button.folder .count.unread{
    background-color: var(--colorBlue);
    color: #fff;
  }


  .filelist li button.folder{
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237B7B7B'%3E%3Cpath d='M15.257 13.837H9.743c-.415 0-.753.346-.753.77v1.733c0 .426.338.772.753.772h5.514c.416 0 .754-.346.754-.772v-1.732c0-.425-.33-.77-.75-.77zm-.215 2.283H9.958v-1.29h5.084v1.29z'/%3E%3Cpath d='M24.755 9.548l.007-.004L21.13 2.19c-.294-.596-.88-.965-1.53-.965h-.692V.54c0-.273-.217-.495-.484-.495H6.576c-.267 0-.484.222-.484.496v.69h-.69c-.65 0-1.238.37-1.53.968L.237 9.548h.007c-.155.262-.245.57-.245.9v7.77c0 .957.76 1.737 1.69 1.737H23.3c.937 0 1.698-.78 1.698-1.738v-7.77c0-.33-.09-.637-.246-.9zm-5.847-7.22h.69c.243 0 .46.137.57.358l2.976 6.023H21.12V6.85c0-.274-.216-.496-.484-.496h-.6V3.7c0-.275-.216-.497-.484-.497h-.644v-.876zm1.244 5.024V8.71h-2.557c-.438 0-.838.264-1.02.673l-.697 1.586c-.006.01-.02.02-.037.02H9.16c-.017 0-.032-.01-.038-.03l-.698-1.58c-.18-.41-.58-.675-1.02-.675H4.85V7.35h15.304zM7.062 1.037H17.94v2.166H7.06V1.037zm12.006 3.158V6.36H5.932V4.195h13.136zM4.83 2.685c.11-.22.33-.358.57-.358h.692v.876h-.644c-.268 0-.485.222-.485.496v2.66h-.6c-.267 0-.484.22-.484.49v1.86H1.85l2.98-6.03zm18.473 16.167H1.697c-.342 0-.62-.285-.62-.635v-7.77c0-.35.278-.635.62-.635h5.708c.016 0 .03.01.037.025l.698 1.585c.18.41.58.674 1.02.674h6.68c.44 0 .84-.264 1.02-.674l.698-1.585c.007-.015.02-.025.037-.025h5.708c.342 0 .62.285.62.635v7.77c0 .35-.278.635-.62.635z'/%3E%3C/g%3E%3C/svg%3E");

    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 12px;
  }

  .filelist li button.file{
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%237B7B7B' d='M19.818 7.217L13.081.157A.507.507 0 0 0 12.714 0H2.672A2.049 2.049 0 0 0 .634 2.054v22.562c0 1.13.914 2.05 2.038 2.05h15.25a2.046 2.046 0 0 0 2.038-2.049V7.572a.515.515 0 0 0-.142-.355zM12.84 1.384l5.531 5.795h-5.53V1.384zm6.103 23.233c0 .565-.458 1.024-1.02 1.024H2.672c-.564 0-1.022-.46-1.022-1.025V2.054c0-.567.458-1.028 1.021-1.028h9.15v6.666a.51.51 0 0 0 .51.513h6.611v16.412z'/%3E%3C/svg%3E");

    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 12px;
  }
  .filelist li button.file.pdf{
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='27' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237B7B7B'%3E%3Cpath d='M24.818 7.217L18.081.157A.507.507 0 0 0 17.714 0H7.672a2.049 2.049 0 0 0-2.038 2.054v7.177h-4.07c-.84 0-1.524.69-1.524 1.537v7.695C.04 19.31.724 20 1.565 20h4.07v4.616c0 1.13.913 2.05 2.037 2.05h15.25a2.046 2.046 0 0 0 2.038-2.049V7.572a.515.515 0 0 0-.142-.355zM17.84 1.384l5.531 5.795h-5.53V1.384zM1.565 18.974a.51.51 0 0 1-.508-.511v-7.695a.51.51 0 0 1 .508-.512h13.733c.28 0 .508.23.508.512v7.695a.51.51 0 0 1-.508.511H1.565zm22.378 5.643c0 .565-.458 1.024-1.02 1.024H7.672c-.564 0-1.022-.46-1.022-1.025V20h8.647c.84 0 1.525-.69 1.525-1.537v-7.695c0-.848-.684-1.537-1.525-1.537H6.651V2.054c0-.567.458-1.028 1.021-1.028h9.15v6.666a.51.51 0 0 0 .51.513h6.611v16.412z'/%3E%3Cpath d='M6.12 13.015a1.145 1.145 0 0 0-.629-.43c-.163-.045-.512-.067-1.049-.067H3.027v4.405h.882v-1.662h.575c.4 0 .704-.02.915-.063a1.257 1.257 0 0 0 .828-.643c.098-.184.146-.411.146-.682 0-.35-.084-.636-.253-.858zm-.756 1.213a.58.58 0 0 1-.275.216c-.117.046-.35.07-.698.07H3.91v-1.25h.426c.318 0 .53.01.635.03a.608.608 0 0 1 .354.195c.094.104.14.236.14.396 0 .13-.033.245-.1.343zm5.25-.507a1.928 1.928 0 0 0-.418-.713 1.46 1.46 0 0 0-.658-.406c-.19-.056-.468-.084-.831-.084H7.094v4.405h1.66c.326 0 .586-.031.78-.093.261-.084.468-.201.62-.352.203-.198.36-.457.469-.778.089-.262.134-.575.134-.937 0-.413-.048-.76-.143-1.042zm-.859 1.81c-.06.197-.136.338-.23.425a.812.812 0 0 1-.357.183 2.384 2.384 0 0 1-.533.042h-.659v-2.918h.397c.36 0 .6.014.724.042a.837.837 0 0 1 .408.208c.107.102.19.244.25.426.06.183.09.444.09.785 0 .34-.03.609-.09.806zm4.751-2.268v-.745H11.51v4.405h.882v-1.872h1.823v-.745h-1.823v-1.043z'/%3E%3C/g%3E%3C/svg%3E");

    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 12px;
  }
  .filelist li button.file.txt{
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='27' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237B7B7B'%3E%3Cpath d='M24.818 7.217L18.081.157A.507.507 0 0 0 17.714 0H7.672a2.049 2.049 0 0 0-2.038 2.054v7.177h-4.07c-.84 0-1.524.69-1.524 1.537v7.695C.04 19.31.724 20 1.565 20h4.07v4.616c0 1.13.913 2.05 2.037 2.05h15.25a2.046 2.046 0 0 0 2.038-2.049V7.572a.515.515 0 0 0-.142-.355zM17.84 1.384l5.531 5.795h-5.53V1.384zM1.565 18.974a.51.51 0 0 1-.508-.511v-7.695a.51.51 0 0 1 .508-.512h13.733c.28 0 .508.23.508.512v7.695a.51.51 0 0 1-.508.511H1.565zm22.378 5.643c0 .565-.458 1.024-1.02 1.024H7.672c-.564 0-1.022-.46-1.022-1.025V20h8.647c.84 0 1.525-.69 1.525-1.537v-7.695c0-.848-.684-1.537-1.525-1.537H6.651V2.054c0-.567.458-1.028 1.021-1.028h9.15v6.666a.51.51 0 0 0 .51.513h6.611v16.412z'/%3E%3Cpath d='M10.68 12.618v.71h1.244v3.484h.847v-3.485h1.242v-.709zm-7.487 0v.71h1.245v3.484h.847v-3.485h1.241v-.709zm5.919 2.037l1.305-2.037h-.981l-.824 1.347-.841-1.347h-.99l1.299 2.005-1.434 2.189h1.016l.933-1.45.93 1.45h1.02z'/%3E%3C/g%3E%3C/svg%3E");

    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 12px;
  }

  .filelist li button.file.unread{
    font-weight: 900;
  }