.customHeader{


    

    padding: 58px 16px 0 16px;
    background-color:var(--colorBackgroundBlue);
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: space-between;
    .text{
        color: white;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        width: calc(100% - 20px);
        margin-left: 20px;
        padding-right: 10px;
    }
    .icon{
        margin-left: auto;
        height: 20px;
        width: 20px;
    }
    height: 120px;
    position: relative;
    z-index: 2;
    box-shadow: 0 3px 5px rgba(0,0,0,.2);

    --padding-top: var(--ion-safe-area-top, 0);
    --ion-background-color:var(--colorBackgroundBlue);
}